import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Spinner.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps } from "vue";
const _hoisted_1 = ["disabled"];
const _hoisted_2 = {
  key: 1,
  class: "flex items-center justify-center"
};
import { Status } from "@injectivelabs/utils";
const __default__ = {
  inheritAttrs: false
};
export default /* @__PURE__ */ _defineComponent({
  ...__default__,
  __name: "Button",
  props: {
    xs: Boolean,
    sm: Boolean,
    lg: Boolean,
    xl: Boolean,
    disabled: Boolean,
    status: {
      type: Object,
      default: () => new Status()
    }
  },
  emits: ["click"],
  setup(__props, { emit }) {
    const props = __props;
    const attrs = useAttrs();
    const classes = computed(() => {
      if (props.xs) {
        return "text-xs leading-4 px-2 h-6";
      }
      if (props.sm) {
        return "text-xs leading-4 px-6 h-8";
      }
      if (props.lg) {
        return "text-base leading-5 px-6 h-10";
      }
      if (props.xl) {
        return "text-base leading-5 px-4 h-12";
      }
      return "text-sm px-6 leading-4 h-9";
    });
    const filteredAttrs = computed(() => {
      const filteredAttrs2 = { ...attrs };
      if (props.disabled) {
        const filteredClass = filteredAttrs2.class.replace(/text-(\w+)-(\d+)/g, "").replace(/bg-(\w+)-(\d+)/g, "");
        return { ...attrs, class: filteredClass };
      }
      return filteredAttrs2;
    });
    const hasBackground = computed(() => {
      if (!attrs.class) {
        return false;
      }
      const classes2 = attrs.class;
      return classes2.includes("bg-");
    });
    function handleClick() {
      if (props.status.isLoading()) {
        return;
      }
      emit("click");
    }
    return (_ctx, _cache) => {
      const _component_AppSpinner = __nuxt_component_0;
      return _openBlock(), _createElementBlock("button", _mergeProps({
        disabled: __props.disabled,
        type: "button",
        role: "button",
        class: [[
          _ctx.$attrs.class,
          _unref(classes),
          {
            "border-transparent": _unref(hasBackground) && !__props.disabled,
            " border-gray-600 bg-transparent text-gray-600 cursor-not-allowed": __props.disabled,
            "hover:bg-opacity-80 hover:text-opacity-80 hover:border-opacity-80": !__props.disabled
          }
        ], "font-bold rounded-md border box-border focus:outline-none"]
      }, _unref(filteredAttrs), { onClick: handleClick }), [
        __props.status && __props.status.isNotLoading() ? _renderSlot(_ctx.$slots, "default", { key: 0 }) : _createCommentVNode("", true),
        __props.status && __props.status.isLoading() ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _createVNode(_component_AppSpinner, {
            sm: "",
            white: ""
          })
        ])) : _createCommentVNode("", true)
      ], 16, _hoisted_1);
    };
  }
});
