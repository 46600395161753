import { default as __nuxt_component_0 } from "/opt/build/repo/node_modules/@injectivelabs/ui-shared/lib/components/Spinner.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export default /* @__PURE__ */ _defineComponent({
  __name: "Spinner",
  props: {
    sm: Boolean,
    md: Boolean,
    lg: Boolean,
    xl: Boolean,
    white: Boolean
  },
  setup(__props) {
    const props = __props;
    const sizeClasses = computed(() => {
      if (props.sm) {
        return "h-4 w-4 border-[3px]";
      }
      if (props.lg) {
        return "h-8 w-8 border-[4px]";
      }
      if (props.xl) {
        return "h-12 w-12 border-[6px]";
      }
      return "h-6 w-6 border-[4px]";
    });
    return (_ctx, _cache) => {
      const _component_BaseSpinner = __nuxt_component_0;
      return _openBlock(), _createBlock(_component_BaseSpinner, {
        class: _normalizeClass(["m-0", [
          _unref(sizeClasses),
          __props.white ? "border-t-gray-200 border-r-gray-200" : "border-t-blue-500 border-r-blue-500"
        ]])
      }, null, 8, ["class"]);
    };
  }
});
